// Libraries
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// Component
import Page from '../Page';
// import Gallery from 'react-grid-gallery';
import ModuleMaisonAccueil from '../ModuleMaisonAccueil/ModuleMaisonAccueil';
import { CONTACTS } from './Commun';

class CentreAccueilFouday extends Page {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		super.componentDidMount();
	}

	contenu() {
		const { t } = this.props;

		return (
			<>
				<p className="mb-5 mt-4 text-justify">
					En 2008, un couple de chrétiens hérite d’une maison de famille à
					Fouday, en Alsace, et décide de faire une donation en vue d’un travail
					d’accueil. Avec l’aide d’amis de la région, des travaux ont été
					entrepris pour rénover ce bien. Actuellement, une famille habite sur
					place et pratique l’hospitalité dans un cadre familial.
				</p>
				<p className="mb-5 text-justify">
					Plusieurs chambres (simple, double ou familiale) permettent d’exercer
					cet accueil. La Bible est lue et méditée chaque soir après le repas
					avec les personnes présentes et chacun prend part à la vie
					communautaire pendant la journée.
				</p>
				{/* <div className="text-center pb-5 pt-5">
					<div className="text-center">
						<video
							controls
							autoplay="true"
							muted
							style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}
						>
							<source
								src="https://www.dropbox.com/s/g623v9icg3uoga4/acb20a6e7472aca4640ce5eeea9538cf041ae3f7.webm?raw=1"
								type="video/webm"
							/>
							<source
								src="https://www.dropbox.com/s/v7aqjh68pejtu6v/3b1d6b18ba4a39c43239755e6afa13b3cf9a724b.mp4?raw=1"
								type="video/mp4"
							/>
							Sorry, your browser doesn't support embedded videos.
						</video>
					</div>
				</div> */}
			</>
		);
	}
	render() {
		const { t } = this.props;

		return (
			<>
				<ModuleMaisonAccueil
					titre="Maison d'accueil"
					sousTitre="Fouday"
					contact={CONTACTS[3]}
					idGallerie="1427"
					contenu={this.contenu()}
				/>
			</>
		);
	}
}

export default withTranslation()(CentreAccueilFouday);
