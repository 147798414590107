import React from 'react';
import { withTranslation } from 'react-i18next';
import './ProgrammeCamps.scss';
import Modal from 'react-bootstrap/Modal';

const DATA = [
	// ANDUZE
	// {
	// 	rank: '1',
	// 	programme: 'anduze-hiver',
	// 	dates: 'Du 19 au 25 décembre 2019',
	// 	type: 'Fermeture'
	// },
	{
		rank: '1',
		programme: 'anduze-hiver',
		dates: 'Du 26 décembre au 1er janvier 2025',
		type: 'Camp de familles'
	},
	// {
	// 	rank: '1',
	// 	programme: 'anduze-hiver',
	// 	dates: 'Du 26 décembre au 1er janvier 2024',
	// 	type: "Camp d'adolescents (mixte)"
	// },
	{
		rank: '2',
		programme: 'anduze-hiver',
		dates: 'Du 15 au 22 février 2025',
		type: 'Camp de familles'
	},
	// {
	// 	rank: '2',
	// 	programme: 'anduze-hiver',
	// 	dates:
	// 		'Du 24 février au 1er mars 2024 <div style="color: #f70;">(initialement prévu 18 au 24 février)</div>',
	// 	type: 'Camp de familles'
	// },
	// {
	// 	rank: '3',
	// 	programme: 'anduze-printemps',
	// 	dates: 'Du 19 au 25 mars 2022',
	// 	type: 'Camp de familles'
	// },
	// {
	// 	rank: '3',
	// 	programme: 'anduze-printemps',
	// 	dates: 'Du 22 au 29 avril 2023',
	// 	type: 'Camp de familles'
	// },
	{
		rank: '3',
		programme: 'anduze-printemps',
		dates: 'Du 12 au 19 avril 2025',
		type: 'Camp de familles'
	},
	// {
	// 	rank: '3',
	// 	programme: 'anduze-printemps',
	// 	dates: 'Du 29 mars au 5 avril 2022',
	// 	type: 'Fermeture'
	// },
	// {
	// 	rank: '3',
	// 	programme: 'anduze-printemps',
	// 	dates: 'Du 14 au 21 juin 2022',
	// 	type: 'Fermeture'
	// },
	{
		rank: '1',
		programme: 'anduze-ete',
		dates: 'Du 6 au 13 juillet 2025',
		type: 'Camp de familles'
	},
	{
		rank: '1',
		programme: 'anduze-ete',
		dates: 'Du 20 au 27 juillet 2025',
		type: 'Camp de familles'
	},
	// {
	// 	rank: '1',
	// 	programme: 'anduze-ete',
	// 	dates: 'Du 28 juillet au 4 août 2024',
	// 	type: 'Camp de familles'
	// },
	{
		rank: '1',
		programme: 'anduze-ete',
		dates: 'Du 2 au 9 août 2025',
		type: 'Camp de familles'
	},
	// {
	// 	rank: '2',
	// 	programme: 'anduze-ete',
	// 	dates: 'Du 12 au 17 juillet 2021',
	// 	type: 'Séjour'
	// },
	// {
	// 	rank: '3',
	// 	programme: 'anduze-ete',
	// 	dates: 'Du 19 au 24 juillet 2021',
	// 	type: 'Séjour'
	// },
	// {
	// 	rank: '4',
	// 	programme: 'anduze-ete',
	// 	dates: 'Du 26 au 31 juillet 2021',
	// 	type: 'Séjour'
	// },
	// {
	// 	rank: '5',
	// 	programme: 'anduze-ete',
	// 	dates: 'Du 2 au 7 août 2021',
	// 	type: 'Séjour'
	// },
	{
		rank: '2',
		programme: 'anduze-ete',
		dates: 'Du 13 au 22 août 2025',
		type: 'Camp de jeunes'
	},
	// {
	// 	rank: '5',
	// 	programme: 'anduze-ete',
	// 	dates: 'Du 17 au 27 juillet 2020 <span style="color: #f00;">ANNULÉ</span>',
	// 	type: 'Camp de jeunes'
	// },
	// {
	// 	rank: '6',
	// 	programme: 'anduze-ete',
	// 	dates: 'Du 1 au 8 août 2020 <span style="color: #f00;">ANNULÉ</span>',
	// 	type: 'Camp de familles'
	// },
	// {
	// 	rank: '7',
	// 	programme: 'anduze-ete',
	// 	dates: 'Du 12 au 19 août 2020 <span style="color: #f00;">ANNULÉ</span>',
	// 	type: 'Camp de familles'
	// },
	// {
	// 	rank: '12',
	// 	programme: 'anduze-ete',
	// 	dates: 'Du 22 au 30 août 2019 <span style="color: #f00;">ANNULÉ</span>',
	// 	type: 'Fermeture'
	// },
	{
		rank: '1',
		programme: 'anduze-automne',
		dates: 'Du 20 octobre au 27 octobre 2024',
		type: 'Camp de familles'
	},

	// RIFFRAY
	{
		rank: '13',
		programme: 'riffray-hiver',
		dates: 'Du 26 décembre au 1er janvier 2025',
		type: 'Camp de familles'
	},
	{
		rank: '13',
		programme: 'riffray-printemps',
		dates: 'Du 6 au 12 avril 2025',
		type: 'Camp de familles'
	},
	{
		rank: '13',
		programme: 'riffray-printemps',
		dates: 'Du 2 au 7 juin 2025',
		type: 'Camp de familles'
	},
	{
		rank: '14',
		programme: 'riffray-ete',
		dates: 'Du 10 au 16 août 2025',
		type: 'Camp de familles'
	},

	// {
	// 	rank: '8',
	// 	programme: 'riffray-automne',
	// 	dates: 'Du 26 au 31 octobre 2020',
	// 	type: 'Camp de familles'
	// },
	// FOUDAY
	{
		rank: '15',
		programme: 'fouday-printemps',
		dates: 'Du 5 au 12 Avril 2025',
		type: 'Camp de familles'
	},
	{
		rank: '16',
		programme: 'fouday-ete',
		dates: 'Du 2 au 9 août 2025',
		type: 'Camp de familles'
	},
	// {
	// 	rank: '17',
	// 	programme: 'fouday-hiver',
	// 	dates: 'Du 23 au 30 décembre 2019',
	// 	type: 'Fermeture'
	// },
	{
		rank: '18',
		programme: 'fouday-automne',
		dates: 'Du 19 au 26 octobre 2024',
		type: 'Camp de familles'
	}
	// {
	// 	rank: '19',
	// 	programme: 'fouday-ete',
	// 	dates: 'Du 17 au 27 juillet 2020',
	// 	type: 'Fermeture'
	// },
	// {
	// 	rank: '20',
	// 	programme: 'fouday-ete',
	// 	dates: 'Du 21 au 29 août 2020',
	// 	type: 'Fermeture'
	// },
	// {
	// 	rank: '21',
	// 	programme: 'riffray-hiver',
	// 	dates: 'Du 25 décembre au 1 janvier 2020',
	// 	type: 'Fermeture'
	// }
];

// const FERMETURE = [
// 	'Du 19 au 25 décembre 2017',
// 	'Du 27 mars au 3 avril 2018',
// 	'Du 19 au 26 juin 2018',
// 	'Du 24 au 31 août 2018'
// ];

class ProgrammeCamps extends React.Component {
	state = {
		camp: '',
		typeCamp: '',
		show: false
	};

	programme(lieuSaison, classe) {
		var dates1 = [];
		var dates2 = [];
		var dates3 = [];
		var dates4 = [];
		var dates5 = [];
		var dates6 = [];
		var typeCamp = '';
		var typeCamp2 = '';
		var typeCamp3 = '';
		var typeCamp4 = '';
		var typeCamp5 = '';
		var typeCamp6 = '';
		var i1 = 0;
		var i2 = 0;
		var i3 = 0;
		var i4 = 0;
		var i5 = 0;
		var i6 = 0;
		DATA.forEach(function(element) {
			if (lieuSaison === element.programme) {
				var typeCampCourant = element.type;
				if (typeCampCourant === 'Camp de familles') {
					typeCamp = 'Camp de familles';
					dates1[i1] = (
						<div dangerouslySetInnerHTML={{ __html: element.dates }} />
					);
					i1++;
				}
				if (typeCampCourant === 'Camp de jeunes') {
					typeCamp2 = 'Camp de jeunes';
					dates2[i2] = (
						<div dangerouslySetInnerHTML={{ __html: element.dates }} />
					);
					i2++;
				}
				if (typeCampCourant === 'Camp hors-saison') {
					typeCamp3 = 'Camp hors-saison';
					dates3[i3] = (
						<div dangerouslySetInnerHTML={{ __html: element.dates }} />
					);
					i3++;
				}
				if (typeCampCourant === 'Fermeture') {
					typeCamp4 = 'Fermeture';
					dates4[i4] = (
						<div dangerouslySetInnerHTML={{ __html: element.dates }} />
					);
					i4++;
				}
				if (typeCampCourant === 'Séjour') {
					typeCamp5 = 'Séjour';
					dates5[i5] = (
						<div dangerouslySetInnerHTML={{ __html: element.dates }} />
					);
					i5++;
				}
				if (typeCampCourant === "Camp d'adolescents (mixte)") {
					typeCamp6 = "Camp d'adolescents (mixte)";
					dates6[i6] = (
						<div dangerouslySetInnerHTML={{ __html: element.dates }} />
					);
					i6++;
				}
			}
		});

		if (i1 === 0 && i2 === 0 && i3 === 0 && i4 === 0 && i5 === 0) {
			return <td className="pasDeCamps"></td>;
		} else {
			return (
				<td className={'camps ' + classe}>
					{i1 > 0 && (
						<>
							<div className="typeCamp pl-3 pt-2 pb-2 mb-2">
								{typeCamp}
								<br />
							</div>
							{dates1.map(item => (
								<div key={item} className="pl-3">
									{item}
									<br />
								</div>
							))}
							<br />
						</>
					)}
					{i2 > 0 && (
						<>
							<div className="typeCamp pl-3 pt-2 pb-2 mb-2">
								{typeCamp2}
								<br />
							</div>
							{dates2.map(item => (
								<div key={item} className="pl-3">
									{item}
									<br />
								</div>
							))}
							<br />
						</>
					)}
					{i3 > 0 && (
						<>
							<div className="typeCamp pl-3 pt-2 pb-2 mb-2">
								{typeCamp3}
								<br />
							</div>
							{dates3.map(item => (
								<div key={item} className="pl-3">
									{item}
									<br />
								</div>
							))}
							<br />
						</>
					)}
					{i4 > 0 && (
						<>
							<div className="typeCamp pl-3 pt-2 pb-2 mb-2 bg-gris4 text-white">
								{typeCamp4}
								<br />
							</div>
							{dates4.map(item => (
								<div key={item} className="pl-3">
									{item}
									<br />
								</div>
							))}
							<br />
						</>
					)}
					{i5 > 0 && (
						<>
							<div className="typeCamp pl-3 pt-2 pb-2 mb-2">
								{typeCamp5}
								<br />
							</div>
							{dates5.map(item => (
								<div key={item} className="pl-3">
									{item}
									<br />
								</div>
							))}
							<br />
						</>
					)}
					{i6 > 0 && (
						<>
							<div className="typeCamp pl-3 pt-2 pb-2 mb-2">
								{typeCamp6}
								<br />
							</div>
							{dates6.map(item => (
								<div key={item} className="pl-3">
									{item}
									<br />
								</div>
							))}
							<br />
						</>
					)}
				</td>
			);
		}
	}

	// listeFermeture() {
	// 	return (
	// 		<>
	// 			{FERMETURE.map(item => (
	// 				<div key={item} className="">
	// 					{item}
	// 					<br />
	// 				</div>
	// 			))}
	// 		</>
	// 	);
	// }

	tableauLarge() {
		return (
			<>
				<table
					className="tableau-programme pt-5 pb-5 d-none d-md-block pt-5 pb-5"
					style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
				>
					<thead>
						<tr>
							<th className="bg-withe ligne"></th>
							<th className="text-center lieu text-white">ANDUZE</th>
							<th className="text-center lieu text-white">RIFFRAY</th>
							<th className="text-center lieu text-white">FOUDAY</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th className="bg-green hiver ligne">
								<div className="saisons text-center">HIVER</div>
							</th>
							{this.programme('anduze-hiver')}
							{this.programme('riffray-hiver')}
							{this.programme('fouday-hiver')}
						</tr>
						<tr>
							<th className="bg-jaune printemps ligne">
								<div className="saisons text-center">PRINTEMPS</div>
							</th>
							{this.programme('anduze-printemps')}
							{this.programme('riffray-printemps')}
							{this.programme('fouday-printemps')}
						</tr>
						<tr>
							<th className="bg-jaune ete ligne">
								<div className="saisons text-center">ÉTÉ</div>
							</th>
							{this.programme('anduze-ete')}
							{this.programme('riffray-ete')}
							{this.programme('fouday-ete')}
						</tr>
						<tr>
							<th className="bg-green automne ligne">
								<div className="saisons text-center">AUTOMNE</div>
							</th>
							{this.programme('anduze-automne')}
							{this.programme('riffray-automne')}
							{this.programme('fouday-automne')}
						</tr>
					</tbody>
				</table>
			</>
		);
	}
	tableau() {
		return (
			<>
				<div className="tableau-programme">
					<table className="pt-3 pb-3 d-block sm-block d-md-none">
						<thead>
							<tr>
								<th className="ligneResponsive"></th>
								<th className="text-center lieu text-white">ANDUZE</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th className="bg-green hiver ligneResponsive">
									<div className="saisonsMobile text-center">HIVER</div>
								</th>
								{this.programme('anduze-hiver', 'tdResponsive')}
							</tr>
							<tr>
								<th className="bg-jaune printemps ligneResponsive">
									<div className="saisonsMobile text-center">PRINTEMPS</div>
								</th>
								{this.programme('anduze-printemps', 'tdResponsive')}
							</tr>
							<tr>
								<th className="bg-jaune ete ligneResponsive">
									<div className="saisonsMobile text-center">ÉTÉ</div>
								</th>
								{this.programme('anduze-ete', 'tdResponsive')}
							</tr>
							<tr>
								<th className="bg-green automne ligneResponsive">
									<div className="saisonsMobile text-center">AUTOMNE</div>
								</th>
								{this.programme('anduze-automne', 'tdResponsive')}
							</tr>
						</tbody>
					</table>
					<table className="pt-3 pb-3 d-block sm-block d-md-none">
						<thead>
							<tr>
								<th className="bg-withe ligneResponsive"></th>
								<th className="text-center lieu text-white">RIFFRAY</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th className="bg-green hiver ligneResponsive">
									<div className="saisonsMobile text-center">HIVER</div>
								</th>
								{this.programme('riffray-hiver', 'tdResponsive')}
							</tr>
							<tr>
								<th className="bg-jaune printemps ligneResponsive">
									<div className="saisonsMobile text-center">PRINTEMPS</div>
								</th>
								{this.programme('riffray-printemps', 'tdResponsive')}
							</tr>
							<tr>
								<th className="bg-jaune ete ligneResponsive">
									<div className="saisonsMobile text-center">ÉTÉ</div>
								</th>
								{this.programme('riffray-ete', 'tdResponsive')}
							</tr>
							<tr>
								<th className="bg-green automne ligneResponsive">
									<div className="saisonsMobile text-center">AUTOMNE</div>
								</th>
								{this.programme('riffray-automne', 'tdResponsive')}
							</tr>
						</tbody>
					</table>
					<table className="pt-3 pb-3 d-block sm-block d-md-none">
						<thead>
							<tr>
								<th className="bg-withe ligneResponsive"></th>
								<th className="text-center lieu text-white">FOUDAY</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th className="bg-green hiver ligneResponsive">
									<div className="saisonsMobile text-center">HIVER</div>
								</th>
								{this.programme('fouday-hiver', 'tdResponsive')}
							</tr>
							<tr>
								<th className="bg-jaune printemps ligneResponsive">
									<div className="saisonsMobile text-center">PRINTEMPS</div>
								</th>
								{this.programme('fouday-printemps', 'tdResponsive')}
							</tr>
							<tr>
								<th className="bg-jaune ete ligneResponsive">
									<div className="saisonsMobile text-center">ÉTÉ</div>
								</th>
								{this.programme('fouday-ete', 'tdResponsive')}
							</tr>
							<tr>
								<th className="bg-green automne ligneResponsive">
									<div className="saisonsMobile text-center">AUTOMNE</div>
								</th>
								{this.programme('fouday-automne', 'tdResponsive')}
							</tr>
						</tbody>
					</table>
				</div>
			</>
		);
	}

	render() {
		const { t } = this.props;
		const BoiteDialogue = () => (
			<Modal
				show={this.state.show}
				onHide={() => this.setState({ show: false })}
			>
				<Modal.Body>
					<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.setState({ show: false })}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h3 className="text-center">
						En raison de la crise sanitaire que nous traversons, nous avons pris
						la <strong>décision d'annuler tous les camps</strong> de cet été.
					</h3>
				</Modal.Body>
			</Modal>
		);
		return (
			<>
				<div className="container">
					<BoiteDialogue />
				</div>
				<div className="container-fluid bg-gris1 pb-4">
					<h1 className="text-center contenu-h1">PROGRAMME RÉUNI DES CAMPS</h1>
					<div className="">{this.tableauLarge()}</div>
				</div>
				<div className="container-fluid bg-gris1 pb-5 no-gutters">
					<div className="container home">{this.tableau()}</div>
				</div>

				{/* <div className="container-fluid bg-blue">
					<div className="row no-gutters">
						<div className="col-md-6 ">
							<div className="row">
								<div className="col-md-2"></div>
								<div className="col-md-8">
									<h2 className="pt-5">DATES DE FERMETURES</h2>
									<h4 className="pt-3">Maison d'accueil d'Anduze</h4>
									<p className="text-white pt-3 mb-5">
										La maison d’accueil est ouverte toute l’année sauf pendant
										les périodes suivantes :
										<br />
										<br />
										{this.listeFermeture()}
									</p>
								</div>
								<div className="col-md-2"></div>
							</div>
						</div>
						<div className="col-md-6">
							<img src={imageGardon} className="img-fluid" />
						</div>
					</div>
				</div> */}
			</>
		);
	}
}

export default withTranslation()(ProgrammeCamps);
